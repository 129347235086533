<template>
  <div :style="'background-color:'+back" class="index-header">
    <span :style="'color:'+color" class="el-icon-arrow-left cursor" @click="goIndex"></span>
    <span :style="'color:'+color">{{title}}</span>
    <span :style="'color:'+color" style="transform: rotate(180deg);" class="el-icon-arrow-left cursor" @click="goIndex"></span>

    <!-- <img src="../assets/close.png" @click="close" class="close-img"> -->
    <!-- <span :style="'color:'+color" @click="close" class="el-icon-close cursor"></span> -->

    <!-- <span></span> -->
  </div>
</template>
<script>
// import { ipcRenderer } from "electron";
// const { ipcRenderer } = window.require('electron')

export default {
  props: {
    back: { type: String, default: '' },
    color: { type: String, default: '' },
    title: { type: String, default: '' },
  },

  methods: {
    close() {
      // ipcRenderer.send('close')
    },
    goIndex() {
      this.$router.push({ path: '/' })
    },
    onMinimize() {
      // ipcRenderer.send('min')
    },
  }
}
</script>
<style>
.cursor {
  font-size: 2vmax;
}
.close-img {
  height: 35px;
  width: 35px;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
</style>