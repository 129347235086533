<template>
  <div>
    <header-home back="#cdd0d2" color="#000000"></header-home>

    <div class="problem-index">
      <div class="problem-head">
        只针对注册用户!
      </div>
      <div class="problem">
        <div class="pronlem-item" v-for="(item,index) in problem" :key="index">
          <div class="pronlem-title">{{index+1}}、{{item.value}}</div>
          <div class="result-div">
            <el-radio-group v-model="result[item.name]" size="small" fill="#2a749b" text-color="#2a749b" :style="deviceType=='Desktop'?'transform:scale(1.5)':''">
              <el-radio fill="#2a749b" text-color="#2a749b" border :disabled="!login" @click.native.prevent="radioChange(item.name,'是')" label="是"></el-radio>
              <el-radio border :disabled="!login" @click.native.prevent="radioChange(item.name,'否')" label="否"></el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 		<div class="submit-div" @click="refer">
			提交
		</div> -->
    </div>
  </div>
</template>
<script>
const detectDeviceType = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop"; 

import headerHome from '../components/headerHome.vue'

export default {
  components: {
    headerHome

  },
  data() {
    return {
      deviceType:detectDeviceType(),
      login: true,
      result: {
        q1: '',
        q2: '',
        q3: '',
        q4: ''
      },
      problem: false,
      usr: false
    };
  },
  mounted() {
    this.usr = this.$stroage.get('user')
    if (!this.usr || !this.usr.phone) {
      this.login = false
    } else {
      this.login = true
      this.result.q1 = this.usr.q1
      this.result.q2 = this.usr.q2
      this.result.q3 = this.usr.q3
      this.result.q4 = this.usr.q4
      console.log(this.result, 'this.result')
    }

    this.$get('data/api.data/starter').then((res) => {
      if (res.code) {
        this.problem = res.data.question
        console.log(this.problem)
      } else {
        this.$message({
          message: res.info,
          type: 'warning'
        });

      }
    })
  },
  methods: {
    radioChange(name, i) {
      if (!this.login) return

      if (this.result[name] == i) {
        this.result[name] = ''
      } else {
        this.result[name] = i
      }
      this.refer(name, i)
    },
    refer(name, i) {
      this.result.user_id = this.usr.id
      this.$post('index/user/update_info', this.result).then((res) => {
        if (res.code) {
          this.usr[name] = i
          this.$stroage.put('user', this.usr)
        } else {
          this.$message({
            message: res.info,
            type: 'warning'

          });
        }
      })
    },
  }
}
</script>
<style>
.el-radio__input.is-checked .el-radio__inner {
  box-shadow: 0 !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #2a749b !important;
}
.el-radio.is-bordered.is-checked {
  border-color: #2a749b !important;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #2a749b !important;
  background: #2a749b !important;
}
.el-radio__label {
  color: #ffffff !important;
}
.problem-index {
  width: 100vmax;
  display: flex;
  flex-wrap: wrap;
  height: 100vmin;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.problem {
  width: 88vmax;
  height: 88vmin;
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  margin: 0 auto;
  /* align-items: center; */
  justify-content: space-between;
}

.pronlem-item {
  width: 45%;
  /* 		display: flex;
		align-items: center;
		justify-content: space-between; */
}

.pronlem-check {
  margin-left: 5rpx;
}

.submit-div {
  width: 10vmax;
  height: 10vmin;
  line-height: 10vmin;
  background-color: #636363;
  border-radius: 20rpx;
  text-align: center;
  color: #ffffff;
  margin: 0 30%;
}

.result-div {
  display: flex;
  align-items: center;
  font-size: 4vmin;
  margin-top: 3vmin;
  justify-content: flex-end;
}

.result-title {
  margin-right: 1.5vmax;
}

.pronlem-title {
  height: 20vmin;
  font-size: 4vmin;
  margin-top: 2vmin;
}

.pad-bottom {
  padding-bottom: 2vmin;
}

.form-input-text {
  font-size: 1.7vmax;
  padding-bottom: 2vmin;
}

.my-form-button {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}

.my-input {
  width: 80%;
}

.content-text {
  color: #ffffff;
  font-size: 1.5vmax;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7vmin;
  background-color: #414141;
  outline: 1px solid #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-talk {
  color: #b2fcce;
  text-align: center;
  white-space: nowrap;
}

.my-margin-top {
  margin-top: 5vmin;
}

.clear-btn {
  background-color: #ffffff;
  padding: 0;
  border-radius: 0 0 5px 0;
  line-height: 50px;
}

.clear-btn::after {
  border: none !important;
}

.member {
  padding: 0px 20px;
}

.member-div {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.member-text {
  font-size: 14px;
  width: 60px;
  text-align: end;
  margin-right: 10px;
}

.modal-btns {
  padding: 4vmin 0;
  font-size: 4vmin;
  text-align: center;
  width: 50%;
}

.member-text {
  font-size: 14px;
  width: 60px;
  text-align: end;
  margin-right: 10px;
}

.my-form-input {
  font-size: 1.6vmax;
}

.clear-btn {
  background-color: #ffffff;
  padding: 0;
  border-radius: 0 0 5px 0;
  line-height: 50px;
}

.clear-btn::after {
  border: none !important;
}

.sendmsg {
  color: #636363;
}

.problem-head {
  text-align: center;
  width: 100%;
  height: 10vmin;
  line-height: 10vmin;
  margin-top: 2vmin;
  color: #414141;
  font-size: 4vmin;
}
</style>