<template>
  <div style="display: flex;">
    <div class="my-left">

      <!-- <span class="my-schedule-header-date gui-icons">{{cYear}} 年 {{cMonthStr}} 月 &#xe603;</span> -->
      <!-- -->
      <div class="div-picker">
        <el-date-picker :editable="false" value-format="yyyy-MM-dd" @change="selectDate" v-model="value" type="date" placeholder="选择日期">
        </el-date-picker>
      </div>

      <span class="gui-border gui-schedule-today" @click="gotoToday">返回今天</span>
    </div>
    <div class="my-right">

      <div class="gui-flex gui-rows gui-nowrap" style="margin-bottom:2vmin;">
        <span class="gui-schedule-weeks gui-color-gray gui-block-text" v-for="(item, index) in weeks" :key="index">{{item}}</span>
      </div>
      <div class="gui-flex gui-rows gui-wrap">
        <div class="gui-schedule-7item gui-flex gui-rows gui-justify-content-center" v-for="(item, index) in days" :key="index">
          <div class="gui-date-ditems gui-flex gui-columns gui-justify-content-center" v-if="item != ''" :style="{
					backgroundColor:currentDayIn == cYear+'-'+cMonthStr+'-'+ item.date 
					? activeBgColor : bgColor
				}" @click="chooseDate(cYear+'-'+cMonthStr+'-'+item.date, item.date)">
            <span class="gui-date-day gui-block-text" :class="[currentDayIn == (cYear+'-'+cMonthStr+'-'+item.date) ? 
					'gui-d-current-txt' : '']">{{item.date}}</span>
            <span class="gui-date-nl gui-block-text" v-if="isLunar" :class="[currentDayIn == (cYear+'-'+cMonthStr+'-'+item.date) ? 
					'gui-d-current-txt' : '']">{{item.nl}}</span>
            <div class="gui-schedule-point" v-if="item.haveSe" :style="{backgroundColor:pointColor}">
            </div>
          </div>
          <div class="gui-date-ditems" v-else style="background-color:none;"></div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import guiCalendar from '../utils/calendar.js';
export default {
  name: "gui-schedule",
  data() {
    return {
      value: '',
      cYear: 2020,
      cMonth: 1,
      cDay: 10,
      cMonthStr: '01',
      weeks: ['一', '二', '三', '四', '五', '六', '日'],
      days: [],
      currentDayIn: '',
      hours: [],
      schedulesIn: []
    }
  },
  props: {
    // 当前默认日期
    currentDate: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: "#F8F8F8"
    },
    activeBgColor: {
      type: String,
      default: "#008AFF"
    },
    isLunar: {
      type: Boolean,
      default: true
    },
    startDate: {
      type: String,
      default: '1950-01-01'
    },
    endDate: {
      type: String,
      default: '2050-01-01'
    },
    schedules: {
      type: Array,
      default: function () {
        return []
      }
    },
    pointColor: {
      type: String,
      default: "#FF0036"
    }
  },
  created: function () {
    this.currentDayIn = this.currentDate;
    this.initTime();
    this.getSchedulesIn();
  },
  methods: {
    initTime: function () {
      if (this.currentDayIn == '') {
        var dateObj = new Date();
        this.cYear = Number(dateObj.getFullYear());
        this.cMonth = Number(dateObj.getMonth() + 1);
        this.cMonthStr = this.cMonth < 10 ? '0' + this.cMonth : this.cMonth;
        this.cDay = dateObj.getDate();
        this.cDay = this.cDay < 10 ? '0' + this.cDay : this.cDay;
        this.currentDayIn = this.cYear + '-' + this.cMonthStr + '-' + this.cDay;
        this.value = this.cYear + '-' + this.cMonthStr + '-' + this.cDay;
        this.changeMonth();
      } else {
        var dates = this.currentDayIn.split(' ');
        if (!dates[1]) {
          dates[1] = '';
        }
        var dayArr = dates[0].split('-');
        this.cYear = Number(dayArr[0]);
        this.cMonth = dayArr[1];
        this.cDay = dayArr[2];
        var reg = new RegExp('^0[0-9]+$');
        if (reg.test(this.cMonth)) {
          this.cMonth = this.cMonth.substr(1, 1);
        }
        this.cMonth = Number(this.cMonth);
        this.cMonthStr = this.cMonth < 10 ? '0' + this.cMonth : this.cMonth;
        this.currentDayIn = dates[0];
        this.currentTimeIn = dates[1];
        this.changeMonth();
      }
    },
    changeMonth: function () {
      var daysList = [];
      var days = this.getDaysInOneMonth();
      var startWeek = this.getDay();
      var forSteps = 0;
      for (var i = (0 - startWeek); i < days; i++) {
        if (i >= 0) {
          daysList[forSteps] = {
            date: i >= 9 ? i + 1 : '0' + (i + 1),
            nl: ''
          };
          daysList[forSteps].nl = guiCalendar.getLunarInfo(this.cYear, this.cMonth, i + 1);
          daysList[forSteps].haveSe = this.haveSchedule(daysList[forSteps].date);
        } else {
          daysList[forSteps] = '';
        }
        forSteps++;
      }
      this.days = daysList;
      console.log(this.days, 'this.days')
    },
    haveSchedule: function (day) {
      var cDay = this.cYear + '-' + this.cMonthStr + '-' + day;
      for (let i = 0; i < this.schedules.length; i++) {
        if (this.schedules[i].datetime.indexOf(cDay) != -1) {
          return true;
        }
      }
      return false;
    },
    getDaysInOneMonth: function () {
      var d = new Date(this.cYear, this.cMonth, 0);
      return d.getDate();
    },
    getDay: function () {
      var d = new Date(this.cYear, this.cMonth - 1, 0);
      return d.getDay();
    },
    selectDate: function (e) {
      // console.log(e)
      this.currentDayIn = e;
      this.initTime();
      this.getSchedulesIn();
      this.$emit('selectDate', e);
    },
    chooseDate: function (sedDate) {
      this.currentDayIn = sedDate;
      this.getSchedulesIn();
      this.$emit('chooseDate', sedDate);
    },
    getSchedulesIn: function () {
      var res = [];
      for (let i = 0; i < this.hours.length; i++) {
        var ctime = this.currentDayIn + ' ' + this.hours[i] + ':00';
        res.push([]);
        for (let ii = 0; ii < this.schedules.length; ii++) {
          if (this.schedules[ii].datetime == ctime) {
            res[i].push(this.schedules[ii]);
          }
        }
      }
      this.schedulesIn = res;
    },
    scheduleTap: function (e) {
      var id = e.currentTarget.dataset.id;
      this.$emit('scheduleTap', id);
    },
    gotoToday: function () {
      var dateObj = new Date();
      this.cYear = Number(dateObj.getFullYear());
      this.cMonth = Number(dateObj.getMonth() + 1);
      this.cMonthStr = this.cMonth < 10 ? '0' + this.cMonth : this.cMonth;
      this.cDay = dateObj.getDate();
      this.cDay = this.cDay < 10 ? '0' + this.cDay : this.cDay;
      this.currentDayIn = this.cYear + '-' + this.cMonthStr + '-' + this.cDay;
      this.changeMonth();
      this.getSchedulesIn();
      this.$emit('gotoToday', this.currentDayIn);
    }
  }
}
</script>
<style>
.div-picker .el-date-editor{
width: auto !important;
}
.div-picker {
  margin-top: 10vmin;
  width: 17vmax;
}
.div-picker input {
  font-size: 3vmin !important;
  width: 17vmax;
  padding-right: 0 !important;
}
.gui-schedule-wrap {
  width: 90vmax;
}

.gui-schedule-header-date {
  height: 12vmax;
  line-height: 12vmax;
  color: #2b2e3d;
  font-size: 2vmax;
}

.gui-schedule-7item {
  width: 11.5vmax;
  margin-bottom: 3vmin;
  position: relative;
}

.gui-schedule-weeks {
  width: 11.5vmax;
  height: 8vmin;
  font-size: 4vmin;
  line-height: 8vmin;
  text-align: center;
}

.gui-date-ditems {
  width: 13vmin;
  height: 13vmin;
  border-radius: 50%;
}

.gui-d-current-txt {
  color: #ffffff !important;
}

.gui-date-day {
  height: 5vmin;
  line-height: 5vmin;
  text-align: center;
  font-size: 3.5vmin;
}

.gui-date-nl {
  height: 4vmin;
  line-height: 4vmin;
  color: #888888;
  font-size: 2.5vmin;
  text-align: center;
}

.gui-schedule-line {
  height: 1vmax;
  border-color: #f8f8f8;
}

.gui-schedule-time-list {
  margin-top: 1vmax;
}

.gui-schedule-timer {
  width: 1vmax;
  font-size: 1vmax;
  line-height: 1vmax;
}

.gui-schedule-body {
  width: 30vmax;
  flex: 1;
  border-color: #f8f8f8;
  margin-top: 0.5vmax;
  margin-bottom: 10vmax;
}

.gui-schedules {
  padding: 10rpx;
  line-height: 30rpx;
  font-size: 22rpx;
  margin-top: 15rpx;
  border-radius: 8rpx;
}

.gui-schedule-time-list-wrap {
  padding: 20rpx;
}

.gui-schedule-today {
  line-height: 5vmin;
  height: 5vmin;
  font-size: 2vmin;
  cursor: pointer;
  color: #828282;
  padding-left: 2vmin;
  padding-right: 2vmin;
  margin-bottom: 50vmin;
  border-color: #f1f2f3;
}

.gui-schedule-point {
  width: 1vmin;
  height: 1vmin;
  border-radius: 1vmin;
  background-color: #ff0036;
  position: absolute;
  right: 1vmin;
  top: 1vmin;
}

.my-schedule-header-date {
  height: 10vmin;
  line-height: 10vmin;
  color: #2b2e3d;
  font-size: 4vmin;
}

.my-left {
  width: 17vmax;
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
}
.my-right {
  width: 83vmax;
}
</style>
