<template>
  <div class="content-top">
    <!--十字符 蓝色小按钮  -->
    <div @click="switchShow(12,'个人物品')" class="blue-icon">
      <img class="blue-icon" src="../assets/1.png">
    </div>
    <div class="top-center">
      <div class="top-floor">
        <div class="white-title" @click="switchShow(27,'安防报警')">
          无安防信号触发
        </div>
        <!-- 时间 天气 -->
        <div class="white-green home-weather" @click="switchShow(1,'时间天气')">
          {{dateFormat(date)}} {{weather}}
        </div>
        <div class="white-title" @click="switchShow(28,'事项提醒')">
          无提醒事项触发
        </div>
      </div>
      <div class="top-floor">
        <div class="white-title" @click="switchShow(29,'呼叫留言')">
          无呼叫留言信息
        </div>
        <div class="top-2floor-center">
          <div class="top-center-icon" @click="switchShow(10,'墙装面板')" :hover-start-time="0">
            <img src="../assets/22.png" />
          </div>
          <div class="top-center-icon" @click="switchShow(22,'住宅网络')" :hover-start-time="0">
            <img src="../assets/wifi.png" />
          </div>
          <!-- 					<div class="white-green" @click="toCity">
						{{city}}
					</div> -->
          <div class="white-green" @click="switchShow(254,'城市地点')">
            {{city}}
          </div>
          <div class="top-center-icon" @click="switchShow(18,'电话信息')" :hover-start-time="0">
            <img src="../assets/3.png" />
          </div>
          <div class="top-center-icon" @click="switchShow(11,'电源供电')" :hover-start-time="0">
            <img src="../assets/15.png" />
          </div>
        </div>
        <div class="white-title" @click="switchShow(30,'健康管理')">
          无健康异常信息
        </div>
      </div>
    </div>

    <!--小人 蓝色小按钮  -->
    <div @click="switchShow(13,'成员状况')" class="blue-icon">
      <img class="blue-icon" src="../assets/7.png">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(),
    }
  },
  props: {
    weather: {
      type: String,
      default: ''
    },
    city: {
      type: String,
      default: '未知'
    }
  },
  mounted() {
    let that = this;
    setInterval(function () {
      that.date = Date.parse(new Date());
    }, 60000);
  },

  methods: {
    switchShow(i, name) {
      this.$emit('change', i, name)
    },
    toCity() {
      this.$jump('/pages/city/city')
    },

    // 获取当前时间
    dateFormat(time) {
     let date = new Date(time);
				let year = date.getFullYear();
				year=(year+'')
				let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				// 拼接
				return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ' ' + this.week();
    },
    week() {
      var a = new Date().getDay();
      a == 0 ? a = 6 : a--;
      var we = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
      return we[a]
    }

  }
}
</script>

<style>
</style>
