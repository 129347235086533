<template>
  <div>
    <div class="initial">
      <div class="initial-left">
        <div class="initial-top">
          <img src="../assets/logo.png" class="initial-logo">
          <div class="initial-title">互联互通 · 智慧之家</div>
        </div>

        <div class="initial-time">{{date.time}}</div>
        <div class="initial-week">{{date.week}}</div>
        <div class="initial-data">{{date.date}}</div>
        <div class="initial-lunar">{{date.lunar}}</div>
      </div>

      <div class="index-division"></div>

      <div>
        <div class="initial-right">
          <div class="initial-city">
            <img class="city-ico" src="../assets/location.png">
            我的屋：{{city}}
          </div>
          <div v-if="weather&&weather[0]" class="weather-index">
            <div v-for="(item,index) in weather" :key="index" class="weather-index-item">
              <img class="sky-img-item" :src="getSky(item)">
              <div class="weather-week">{{index==0?'今天':index==1?'明天':week(item.week)}}</div>
              <div class="day-temp">{{item.dayweather}}~{{item.nightweather}}
                {{item.nighttemp}}°C~{{item.daytemp}}°C</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="initial-buttom">
      <div @click="btn1" class="min-btn">
        日历查询
      </div>
      <div @click="toCity" class="min-btn">
        城市设置
      </div>
      <div @click="btn3" class="min-btn">
        问卷调查
      </div>
      <div @click="btn2" class="min-btn">
        软件说明
      </div>
    </div>
    <span class="initial-version">{{version}}</span>
  </div>
</template>
<script>
import { getLunar } from '../utils/util'
import config from '../../package.json'

var t = 0
var usr
export default {
  data() {
    return {
      weather: '',
      version: config.version,
      date: '',
      boxType: true,
      city: '',
      type: true
    }
  },
  created() {
    this.date = getLunar()
    this.toIndex()
    t = 0
  },
  mounted() {
    this.type = true
    usr = this.$stroage.get('user')
    if (usr && usr.id) {
      this.city = usr.region_city
      this.updateVip(usr.id)
    } else if (!usr || !usr.region_city) {
      usr = {}
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError)
      } else {
        this.$message({
          message: '定位失败,请手动选择城市！',
          type: 'warning'
        });
        var that = this
        setTimeout(() => {
          that.toCity()
        }, 1000);
      }
    } else {
      this.city = usr.region_city
      this.getWeather()
    }
  },
  methods: {
    //定位数据获取成功响应
    onSuccess(position) {
      var longitude = position.coords.longitude
      var latitude = position.coords.latitude
      this.getIp(longitude, latitude)

    },
    // //定位数据获取失败响应
    onError() {
      this.$message({
        message: '定位失败,请手动选择城市！',
        type: 'warning'
      });
      var that = this
      setTimeout(() => {
        that.toCity()
      }, 1000);
    },
    updateVip(id) {
      var that = this
      setTimeout(() => {
        that.$post('index/user/getUserInfo', { id: id }).then((res) => {
          if (res.code) {
            usr = res.data
            if (res.data && res.data.region_city) {
              this.city = usr.region_city
              this.getWeather()
            } else {
              this.getIp()
            }
            this.$stroage.put('user', res.data, 100000)
          }
        })
      }, 100)
    },
    btn2() {
      this.type = false
      // this.$router.push({ path: '/show' })
      this.$router.push({ path: 'explain' })
    },
    btn3() {
      this.type = false
      this.$router.push({ path: 'problem' })
    },
    toCity() {
      this.type = false
      t = 0
      this.$router.push({ path: 'city' })
    },
    btn1() {
      this.type = false
      this.$router.push({ path: 'calendar' })
    },
    getSky(res) {
      let day = res.dayweather
      let night = res.nightweather
      if (day.indexOf("雪") != -1 || night.indexOf("雪") != -1) {
        return require('../assets/snow.png')
      } else if (day.indexOf("雨") != -1 || night.indexOf("雨") != -1) {
        return require('../assets/rain.png')
      } else if (day.indexOf("晴") != -1 || night.indexOf("晴") != -1) {
        return require('../assets/Sunny.png')
      } else {
        // 多云
        return require('../assets/Sunny.png')
      }
    },
    week(weeks) {
      var we = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
      if (weeks) {
        return we[weeks - 1]
      } else {
        var a = new Date();
        var h = a.getDay() - 1;
        return we[h]
      }
    },
    getIp(i1, i2) {
      console.log(i1, i2, 'resss')
      // let ip = localStorage.getItem('ip')
      // conosle.log(ip, 'ippp')
      this.$get('https://restapi.amap.com/v3/geocode/regeo?output=JSON', { key: '57c239178a284b0d2b34534d50d2577b', location: i1 + ',' + i2 }).then((res) => {
        if (res.status == 1) {

          let area = res.regeocode.addressComponent
          console.log(area)
          if (typeof area.city == 'string') {
            this.city = area.city
          } else {
            this.city = area.province
          }
          if (usr && usr.region_city != this.city) {
            usr.region_city = this.city
            if (usr.id) {
              this.update()
            }
          } else if (!usr) {
            usr = {
              region_city: this.city
            }
          }
          this.$stroage.put('user', usr, 100000)
          this.getWeather()
        } else {
          this.toCity()
        }
      })
    },
    update() {
      this.$post('index/user/update_info', {
        region_city: this.city,
        user_id: usr.id
      }).then(() => {

      })
    },
    getWeather() {
      let data = {
        extensions: 'all',
        key: '57c239178a284b0d2b34534d50d2577b',
        city: this.city
      }

      this.$get('https://restapi.amap.com/v3/weather/weatherInfo', data).then((res) => {
        if (res.status == 1) {
          this.weather = res.forecasts[0].casts
        } else {
          this.$message({
            message: '定位失败,请手动选择城市！',
            type: 'warning'
          });
          var that = this
          setTimeout(() => {
            that.toCity()
          }, 1000);
        }
      })
    },
    toIndex() {
      var that = this
      if (this.type && t <= 4000) {
        setTimeout(() => {
          that.toIndex()
          t += 100
        }, 100);
      } else if (!this.city) {
        that.$message({
          message: '定位失败,请手动选择城市！',
          type: 'warning'
        });
        setTimeout(() => {
          that.toCity()
        }, 1000);
      } else if (that.type) {
        that.$router.push({ path: '/home', query: { weather: this.weather[0].dayweather + '~' + this.weather[0].nightweather, air: this.weather[0].nighttemp + '°C ~ ' + this.weather[0].daytemp + '°C' } })
        t = 0
      }
    },
  }
}
</script>
<style>
body {
  overflow: hidden;
  background-image: linear-gradient(-10deg, #636363, #f8fdff);
  height: 100vmin;
}

@media screen and (orientation: portrait) {
  /*竖屏样式*/
  body {
    background-image: linear-gradient(40deg, #636363, #f8fdff) !important;
  }
}
.initial-version {
  position: absolute;
  right: 8vmin;
  bottom: 0.5vmin;
  font-size: 2vmin;
  color: #919191;
}
.el-carousel__button {
  width: 1.5vmax !important;
}
.initial {
  padding: 2vmin 3vmax 0;
  width: 94vmax;
  height: 80vmin;
  display: flex;
  align-items: center;
}

.initial-top {
  display: flex;
  align-items: center;
  position: absolute;
  top: 7vmin;
}
.weather-index-item {
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 21vmax;
  justify-content: center;
  height: 32vmin;
  align-items: center;
}
.initial-logo {
  width: 7vmin;
  margin-right: 5px;
}

.initial-title {
  font-size: 3.5vmin;
  font-weight: 600;
  color: #000000;
}

.city-ico {
  width: 2.5vmax;
  margin-right: 5px;
}

.initial-left {
  color: #2a749b;
  text-align: center;
  font-weight: 500;
  width: 30vmax;
}

.initial-time {
  margin-top: 5vmin;
  font-size: 14vmin;
}

.initial-week {
  margin-top: 2vmin;
  font-size: 7vmin;
}

.initial-data {
  margin-top: 4vmin;
  font-size: 7vmin;
  letter-spacing: 0.5vmin;
}

.initial-lunar {
  margin-top: 4vmin;
  font-size: 4vmin;
  letter-spacing: 1vmin;
  font-weight: 700;
}

.index-division {
  border-right: 1px solid #818181;
  height: 50vmin;
  margin-right: 4vmax;
  margin-left: 4vmax;
}

.initial-city {
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 6vmin;
  width: 51vmax;
  font-size: 2vmax;
}

.weather-index {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10vmin;
}

.weather-index > view:nth-child(3),
.weather-index > view:nth-child(4) {
  margin-top: 6vmin;
}

.weather-index-item {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.sky-img-item {
  width: 20vmin;
  margin-bottom: 2vmin;
}

.initial-right {
  width: 55vmax;
}

.weather-week {
  color: #ceffe1;
  font-size: 3.5vmin;
  margin-bottom: 1vmin;
}

.day-temp {
  color: #ceffe1;
  font-size: 2vmax;
  word-wrap: break-word;
  white-space: normal;
  display: inline;
  text-align: center;
  width: 100%;
}

/* 	.countdown{
		position: absolute;
		top: 40px;
		right: 20px;
	} */
.initial-buttom {
  margin-top: 2vmin;
  display: flex;
  width: 100vmax;
  justify-content: space-around;
}

.min-btn {
  font-size: 1.8vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2a749b;
  height: 5vmax;
  width: 10vmax;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  border-radius: 50px;
  cursor: pointer;
}

.close-index-img {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
</style>