import axios from 'axios'

var BaseURL = 'https://api.wise-house.cn'

const http = axios.create({
    baseURL: BaseURL,
    timeout: 10000,
  })

function get(url, params = {}) {
    return new Promise((resolve, reject) => {
      http({
        url,
        method: 'get',
        params,
      })
        .then((res) => {
        //   if (res.data.code != 0) {
        //   } 
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  function post(url, data) {
    return new Promise((resolve, reject) => {
      http({
        url,
        method: 'post',
        data,
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

export { get, post }
