<template>
  <div class="city">
    <header-home back="#f8f8f8" color="#000000"></header-home>
    <div class="city-content">
      <div class="city-top">
        <div class="city-title">当前选择：
          <span class="city-title-span">{{city[1]?city[1]:''}}</span>
        </div>
        <el-button @click="isCity" class="city-btn" type="primary">确认选择</el-button>
      </div>
      <el-cascader-panel v-model="city" @change="change" size="medium" :options="options"></el-cascader-panel>
    </div>
  </div>
</template>
<script>
import { provinceData, cityData } from '../utils/city.js';
import headerHome from '../components/headerHome.vue'

let user = {}
export default {
  components: {
    headerHome
  },
  data() {
    return {
      options: [],
      activeName: 'calendar',
      city: ['', '']
    };
  },
  mounted() {
    user = this.$stroage.get('user')
    console.log(user, 'useee')
    if (user && user.region_city) {
      this.city[1] = user.region_city
    }

    for (let i = 0; i < provinceData.length; i++) {
      provinceData[i].children = cityData[i];
    }
    this.options = provinceData;
    this.$stroage.put('type', 0)
  },
  methods: {
    change() {
      console.log(this.city)
    },
    isCity() {
      if (user && user.id) {
        user.region_city = this.city[1]
        this.$post('index/user/update_info', {
          region_city: this.city[1],
          user_id: user.id
        }).then(() => {

        })
        this.$stroage.put('user', user)
        this.$message({
          message: '选择成功!',
          type: 'success'
        });
        this.$router.push({ path: '/' })
      } else {
        this.$stroage.put('user', {
          region_city: this.city[1]
        })
        // this.$stroage.put('user', user)
        this.$message({
          message: '选择成功!',
          type: 'success'
        });
        this.$router.push({ path: '/' })
      }
    }
  }
};
</script>
<style>
.city-btn {
  font-size: 2vmin !important;
}
.city {
  height: 100vmin;
  width: 100vmax;
  background-color: #ffffff;
}
.city-top {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 1vmax;
}
.city-title {
  font-size: 2vmax;
}
.city .el-cascader-menu__wrap {
  height: 68vmin !important;
}

.city-content {
  margin: 2vmin 10vmax;
}
.city-title-span {
  color: #409eff;
  font-size: 2.5vmax;
}
.city .el-cascader-node {
  padding-left: 6vmax !important;
  padding-right: 3vmax !important;
  width: 20vmax;
}
.city .el-cascader-node__postfix {
  margin-right: 2vmin;
}
.city .el-cascader-panel.is-bordered {
  border: 1px solid #525252 !important;
  padding: 3vmin 0;
}
.city .el-cascader-menu {
  border-right: 1px solid #525252 !important;
}
.city .el-cascader-menu {
  color: #000000;
  font-size: 1.5vmax !important;
}
.el-cascader-menu:last-child {
  border-right: none !important;
}
</style>