import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import ElementUI from 'element-ui';
import {post,get} from './utils/http.js';
import 'element-ui/lib/theme-chalk/index.css';
import LS from 'cz-storage'
// import { ipcMain } from 'electron'
// ipcMain.on('min',()=>mainWindow.minimize())
// ipcMain.on('max',()=>mainWindow.maximize())
// ipcMain.on('close',()=>mainWindow.close())
Vue.config.productionTip = false
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$stroage = LS;
Vue.use(ElementUI);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
