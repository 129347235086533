<template>
	<!-- 右侧内容 -->
	<div class="side-div">
		<div @click="switchShow(16,'卫浴洁具')" class="max-btn">
			<span class="btn-span">卫浴洁具</span>
		</div>
		<div @click="switchShow(24,'电脑设备')" class="img-white">
			<img src="../assets/25.png" class="img-white" />
		</div>
		<div @click="switchShow(5,'冷暖新风')" class="img-blue">
			<img src="../assets/9.png" class="btn-img" />
		</div>
		<div @click="switchShow(6,'门窗门锁')" class="img-blue">
			<img src="../assets/10.png" class="btn-img" />
		</div>
		<div @click="switchShow(7,'影音设施')" class="img-blue">
			<img src="../assets/12.png" class="btn-img" />
		</div>
		<div @click="switchShow(19,'监控对讲')" class="img-white">
			<img src="../assets/26.png" class="img-white" />
		</div>
		<div @click="switchShow(34,'家居软装')" class="max-btn">
			<span class="btn-span">家居软装</span>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			switchShow(i,name) {
				this.$emit('change', i,name)
			}
		}
	}
</script>

<style>
</style>
