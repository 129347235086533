<template>
  <div id="app">
    <!-- <p>
      <router-link to="/">组件1</router-link>
      <router-link to="/home">组件2</router-link>
    </p> -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  /* position: fixed; */
  /* left: 0; */
  /* top: 0; */
  width: 100%;
  height: 100%;
  /* display: flex; */
}
  /*竖屏样式*/

@media screen and (orientation: portrait) {
  body {
    transform-origin: 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }
}
.box {
  width: 100%;
  height: 100px;
  background: #009f95;
}
</style>
