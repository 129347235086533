<template>
  <div class="content-bottom">
    <!-- <div class="bottom-title-blue" @click="switchShow(14,'家庭空间')">
      家庭空间 
    </div> -->
    <img src="../assets/11.png" class="bottom-title-blue" @click="switchShow(14,'家庭空间')" />

    <div class="bottom-title-white" @click="switchShow(20,'一层客厅')">
       <!-- @touchstart="living=true" @touchend="living=false" -->
      {{living?'客厅':'一层客厅'}}
    </div>
    <div class="bottom-icon">
      <div class="bottom-icon-item" @click="switchShow(21,'音乐音频')">
        <img src="../assets/20.png" class="bottom-icon-item">
      </div>
      <div class="bottom-icon-item" @click="switchShow(17,'家养动物')">
        <img src="../assets/27.png" class="bottom-icon-item">
      </div>

      <div class="bottom-icon-mine" @click="switchShow(23,'智能家居系统')">
        <img src="../assets/6.png" class="bottom-icon-mine">
      </div>
      <div class="bottom-icon-item" @click="switchShow(33,'影相书籍')">
        <img src="../assets/24.png" class="bottom-icon-item">
      </div>
      <div class="bottom-icon-item" @click="switchShow(25,'盆景绿植')">
        <img src="../assets/28.png" class="bottom-icon-item">
      </div>
    </div>
    <div class="bottom-title-white" @click="switchShow(26,'用户登录')">
       <!-- @touchstart="sign=true" @touchend="sign=false" -->
      {{sign?'登录':'用户登录'}}
    </div>
    <img src="../assets/16.png" class="bottom-title-blue" @click="switchShow(15,'身份权限')" />

    <!-- <div class="bottom-title-blue" @click="switchShow(15,'身份权限')">
      身份权限
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      living: false,
      sign: false
    }
  },
  methods: {
    switchShow(i, name) {
      console.log(i)
      this.$emit('change', i, name)
    }
  }
}
</script>

<style>
.bottom-title-blue {
	width: 8vmax;
	cursor: pointer;
}
</style>