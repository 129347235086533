<template>
  <div>
    <nav-com nav="4"></nav-com>
    <header-home back="#cdd0d2" color="#000000"></header-home>

    <img class="explain-image" :src="image" alt="">
  </div>
</template>
<script>
import headerHome from '../components/headerHome.vue'

export default {
  components: {
    headerHome

  },
  data() {
    return {
      image: '',
    };
  },
  mounted() {
    this.$get('data/api.data/starter').then((res) => {
      if (res.code == 1) {
        this.image = 'https:' + res.data.readmeImg
      }
    })
  }
}
</script>
<style>
.explain-image {
  width: 100vmax;
  margin: 1vmin 1vmin 0;
}
</style>