<template>
	<!-- 左侧内容 -->
	<div class="side-div">
		<!-- 净水能源  大-灰色按钮-->
		<!--  -->
<!-- 		<div @click="switchShow" class="max-btn">
			<span class="btn-span">测试按钮</span>
		</div> -->
		<div @click="switchShow(31,'净水能源')" class="max-btn">
			<span class="btn-span">净水能源</span>
		</div>
		<!-- 电梯 白色按钮-->
		<div @click="switchShow(8,'电梯设备')" class="img-white">
			<img src="../assets/5.png" class="img-white" />
		</div>
		<!-- 灯具照明 侧边蓝色按钮-->
		<div @click="switchShow(2,'灯具照明')" class="img-blue">
			<img src="../assets/2.png" class="btn-img" />
		</div>
		<!-- 电器设备 侧边蓝色按钮-->
		<div @click="switchShow(3,'电器设备')" class="img-blue">
			<img src="../assets/4.png" class="btn-img" />
		</div>
		<!-- 窗帘卷帘 侧边蓝色按钮-->
		<div @click="switchShow(4,'电动窗帘')" class="img-blue">
			<img src="../assets/23.png" class="btn-img" />
		</div>
		<!-- 汽车 白色按钮-->
		<div @click="switchShow(9,'交通工具')" class="img-white">
			<img src="../assets/8.png" class="img-white" />
		</div>
		<!-- 运动饮食  大-灰色按钮-->
		<div @click="switchShow(32,'运动饮食')" class="max-btn">
			<span class="btn-span">运动饮食</span>
		</div>
	</div>
</template>

<script>
	export default {
		methods: {
			switchShow(i,name){
				this.$emit('change',i,name)
			}
		}
	}
</script>

<style>

</style>
