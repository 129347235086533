<template>
  <div class="calendar">
    <header-home back="#f8f8f8" color="#000000"></header-home>
    <div class="my-margin">
      <schedule :schedules="schedules" @scheduleTap="scheduleTap" @chooseDate="chooseDate" @selectDate="selectDate" v-if="schedules != null"></schedule>
    </div>
  </div>
</template>
<script>
// import navCom from '../components/navCom.vue'
import headerHome from '../components/headerHome.vue'
import schedule from '../components/scheduleHome.vue'
export default {
  components: {
    headerHome,
    schedule
  },
  data() {
    return {
      schedules: []
    }
  },
  methods: {
    scheduleTap: function (id) {
      console.log(id)
      // uni.showToast({
      // 	title: "id " + id,
      // 	icon: "none"
      // });
    },
    chooseDate: function (e) {
      console.log("客户选择了日期 : " + e);
    },
    selectDate: function (e) {
      console.log("客户从日期picker选择日期 : " + e);
    }
  }
}
</script>

<style>
@import url("../assets/public.css");
	.my-margin{
		margin:1vmax 1vmax 0;
	}
.calendar {
  width: 100vmax;
  height: 100vmin;
  background-color: #ffffff;
}
/*  */
</style>
