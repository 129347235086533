import Vue from 'vue'
import VueRouter from 'vue-router'
import indexPage from '../pages/indexPage.vue'
import homePage from '../pages/homePage.vue'
import calendarPage from '../pages/calendarPage.vue'
import cityPage from '../pages/cityPage.vue'
import explainPage from '../pages/explainPage.vue'
import problemPage from '../pages/problemPage.vue'
//2.调用vue.use() 函数，把 VueRouter 安装为 Vue 的插件
//vue.use()函数的作用，就是来安装插件的
Vue.use(VueRouter)
//3.创建路由的实例对象
const router = new VueRouter({
    routes: [{
        path: '/',
        name: 'index',
        component: indexPage
    }, {
        path: '/home',
        name: 'home',
        component: homePage
    }, {
        path: '/calendar',
        name: 'calendar',
        component: calendarPage
    }, {
        path: '/city',
        name: 'city',
        component: cityPage
    }, {
        path: '/explain',
        name: 'explain',
        component: explainPage
    }, {
        path: '/problem',
        name: 'problem',
        component: problemPage
    }]
})

//4.向外共享路由的实例对象
export default router
