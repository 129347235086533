<template>
  <div class="index-page" @click="heng">
    <header-home title='我的屋' back="#414141" color="#ffffff"></header-home>
    <div class="index" style="height:91vmin">
      <left class="side" @change="switchShow"></left>
      <div class="content">
        <top :weather="weather" :city="city" @change="switchShow"></top>
        <!-- <div class="content-show" style="height:67vmin"> -->
        <div class="content-show" style="height:67vmin" :style="spread?'':'position: relative;'">
          <div class="content-head">
            <div class="white-bfe head-mag">
              登录号码：{{usr&&usr.phone?usr.phone:'无'}}
            </div>
            <!-- <div class="white-bfe font-width">
              
            </div> -->
            <!-- <div class="white-grey">
              有权限/无权限
            </div> -->
            <div class="white-bfe big-font">
              {{title}}
            </div>

            <!-- <div class="white-grey">
              执行中/不执行
            </div> -->
            <div class="white-bfe head-mag">
              是否会员：{{usr&&usr.vip_code?'是':'否'}}
            </div>
            <!-- <div class="white-bfe font-width">
            </div> -->
          </div>
          <div class="content-play" style="height:54.3vmin">
            <!-- 世界城市 -->
            <div class="word-weather" v-if="allWeather" style="height:54.3vmin">
              <div class="word-day" v-if="wordDate">
                <div class="word-time">
                  {{wordDate.time}}
                </div>
                <div class="word-week">
                  {{wordDate.week}}
                </div>
                <div class="word-days">
                  {{wordDate.day}}
                </div>
              </div>
              <div class="division"></div>
              <div class="word-right">
                <div class="word-title">
                  <img class="city-i" src="../assets/location.png">
                  {{wordDate.city}}
                </div>
                <div class="weather">
                  <div class="weather-item" v-for="(item,index) in 4" :key="index">
                    <img class="sky-item" :src="getSky(allWeather[index])">
                    <div class="weather-day">
                      {{healdWeather(index,allWeather[index])}}
                    </div>
                    <div class="weather-day">
                      {{allWeather[index].wea}}
                      {{allWeather[index].tem2}}°C~{{allWeather[index].tem1}}°C
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 视频 -->
            <!-- @click="showVideo=!showVideo" -->

            <!--  -->
            <video 
            class="show-video" 
            autoplay 
            id="myVideo"
            webkit-playsinline="false" 
            playsinline="false" 
            loop="loop" 
            controlsList="noremoteplayback noplaybackrate nodownload" 
            controls 
            x5-video-player-type="h5-page" 
            x5-playsinline="false" 
            x5-video-orientation="portrait" 
            x5-video-player-fullscreen="false" 
            disablePictureInPicture 
            style="object-fit: fill !important;" 
            x-webkit-airplay="false" 
            v-else-if="showVideo&&videoItem">
              <source :src="videoItem" type="video/mp4">
            </video>
            <!-- 轮播图 -->
            <el-carousel :autoplay="!spread" :class="spread?'full-screen':''" :height="!spread?'54.3vmin':'100vmin'" :interval="5000" v-else-if="swiperItems.length" ref="carousel" direction="horizontal">
              <el-carousel-item v-for="(item,index) in swiperItems" :key="index">
                <img :src="item.img" @click="showImg(index)" class="homr-banner">
              </el-carousel-item>
            </el-carousel>
          </div>

          <div id="carousel1" class="content-foot" v-if="!spread&&(free.length!=0||charge.length!=0)">
            <div v-if="free[0]" @click="handle(free[0])" :class="'min-home-btn'+(free[0].id==clickId?' click-btn':'')">
              <span style="text-align: center;">{{free[0].name}}</span>
            </div>
            <div class="el-icon-d-arrow-left buttom-toleft" @click="changeSwiper(-1)"></div>

            <el-carousel arrow="never" class="btn-scroll" indicator-position="none" ref="carousel2" height="7vmin" :autoplay="false">
              <el-carousel-item class="swiper-items" v-for="(items,index) in charge" :key="index">
                <div v-for="(item,i) in items" :key="i" @click="handle(item)" :class="'cen-btn min-home-btn'+(item.id==clickId?' click-btn':'')">
                  <span style="text-align: center;">{{item.name}}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="el-icon-d-arrow-right buttom-toright" @click="changeSwiper(1)"></div>
            <div v-if="free[1]" @click="handle(free[1])" :class="'min-home-btn'+(free[1].id==clickId?' click-btn':'')">
              <span style="text-align: center;">{{free[1].name}}</span>
            </div>
          </div>
          <div class="content-span" v-show="!spread&&free.length==0&&charge.length==0">
            <div id="scroll" style="width:90%">
              <div id="talk" class="content-talk" :class="roll?'roll-div':''" :style="roll?'transition: all '+second+'s linear;'+'left:-'+left+'px;':''">{{talk}}
              </div>
            </div>
          </div>
        </div>
        <bottom @change="switchShow"></bottom>
      </div>
      <right @change="switchShow" class="side"></right>
    </div>

    <!-- <el-carousel :autoplay="false" v-if="spread" class="showImg" ref="carousel" height="100vmin" direction="horizontal">
      <el-carousel-item v-for="(item,index) in swiperItems" :key="index">
        <img :src="item.img" @click="showImg(index)" class="homr-banner">
      </el-carousel-item>
    </el-carousel> -->

    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :class="deviceType!='Desktop'?'dialog-mobile':''" :top="deviceType=='Desktop'?'20vmin':'0'" class="home-dialog" title="注册" :visible.sync="dialogVisible" :width="deviceType=='Desktop'?'30vmax':'90vmin'">
      <el-form :model="form" label-position="right" label-width="20%">
        <el-form-item label="手机号">
          <el-input-number :controls="false" v-model="form.phone" style="width:80%" />
        </el-form-item>
        <el-form-item label="验证码">
          <el-input v-model="form.sms_code" style="width:80%">
            <template slot="append">
              <div @click="getVCode" class="input-code">{{vcodeBtnName}}</div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">注 册</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script type="text/javascript" defer="true">
const detectDeviceType = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? "Mobile" : "Desktop";

let carousel = false
let wid1
let wid2
import left from '../components/leftHome.vue'
import right from '../components/rightHome.vue'
import top from '../components/topHome.vue'
import bottom from '../components/bottomHome.vue'
import headerHome from '../components/headerHome.vue'

import { sloarToLunar } from '../utils/util'
export default {
  components: {
    left,
    right,
    top,
    bottom,
    headerHome
  },
  data() {
    return {
      deviceType: detectDeviceType(),
      city: '南京市',
      weather: '晴',
      title: '今天',
      usr: false,
      suffix: '', //图片后缀
      free: [],
      charge: [],
      swiperItems: false,
      videoItem: '',
      clickId: -1,
      spread: false,
      dialogVisible: false,
      countNum: 120,
      countDownTimer: null,
      form: {},
      vcodeBtnName: "发送验证码",
      showVideo: true,
      second: '0',
      left: '0',
      roll: false,
      talk: ' ',
      wordDate: false,
      allWeather: false
    }
  },
  mounted() {
    this.weather = (this.$route.query.weather || '多云') + (' ' + (this.$route.query.air || ''))
    this.$post('index/CodeNumber/getRemarks').then((res) => {
      if (res.code && res.data && res.data.content) {
        this.talk = res.data.content
        this.getWidth()
      }
    })

    this.usr = this.$stroage.get('user')
    this.city = this.usr.region_city

    let w = window.innerWidth * 2
    this.suffix = '?x-oss-process=image/resize,w_' + w
    this.$post('index/user/dailyAssets').then((res) => {
      if (res.code == 1) {
        let data = res.data
        this.swiperItems = []
        data.forEach((item) => {
          this.swiperItems.push({
            img: 'https:' + item.remark.split("?")[0] + this.suffix
          })
        })

        // this.swiperItems = []
      }
    })
    this.$stroage.put('type', 1)

    var that = this
    window.addEventListener("wheel", function (e) {
      // that.$stroage.put('type', true)
      var type = that.$stroage.get('type')
      let evt = e || window.event;
      if (carousel || !type) {
        return
      }
      evt.preventDefault();
      if (evt.deltaY > 0 && that.swiperItems) {
        that.$refs.carousel.next()
      } else if (that.swiperItems) {
        that.$refs.carousel.prev()
      }
      carousel = true
      setTimeout(() => {
        carousel = false
      }, 200);

    }, {
      passive: false
    });

  },

  methods: {
    // 轮播手滑切换
    slideBanner() {
      let that = this
      //选中item的盒子
      var box = document.getElementById('carousel1')
      //手指起点X坐标
      var startPoint = 0
      //手指滑动重点X坐标
      var stopPoint = 0

      //重置坐标
      var resetPoint = function () {
        startPoint = 0
        stopPoint = 0
      }

      //手指按下
      box.addEventListener('touchstart', function (e) {
        //手指按下的时候停止自动轮播
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX
      })
      //手指滑动
      box.addEventListener('touchmove', function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX
      })
      //当手指抬起的时候，判断图片滚动离左右的距离
      box.addEventListener('touchend', function () {
        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint()
          return
        }
        if (startPoint - stopPoint > 0) {
          resetPoint()
          that.$refs.carousel2.next()
          return
        }
        if (startPoint - stopPoint < 0) {
          resetPoint()
          that.$refs.carousel2.prev()
          return
        }
      })
    },
    heng() {
      window.screen.orientation.lock("landscape-primary");

    },
    getWidth() {
      setTimeout(() => {
        wid1 = document.getElementById('talk').offsetWidth
        wid2 = document.getElementById('scroll').offsetWidth
        if (wid1 > wid2) {
          this.roll = true
          this.left = wid1 + 100
          this.second = this.talk.length / 3.5
          this.second = this.second.toFixed(1)
          this.slide()
        }
      }, 100)

    },

    slide() {
      var that = this
      let t = (Number(this.second) + 2) * 1000
      setInterval(() => {
        that.roll = false
        that.left = 0
        setTimeout(() => {
          that.roll = true
          that.left = wid1 + 100
        }, 200)
      }, t)
    },
    submit() {
      if (this.form.sms_code.length != 5) {
        this.$message({
          message: '请正确填写验证码',
          type: 'warning'
        });
        return
      }
      this.form.phone = this.form.phone + ''
      this.form.region_city = this.city
      this.$post('index/user/appLogin', this.form).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: '登录成功',
            customClass: 'myMessage',
            type: 'success'
          });
          // console.log(res, 'ressss')
          this.usr = res.data
          this.$stroage.put('user', this.usr, 1000000)
          this.dialogVisible = false
        } else {
          this.$message.error(res.info);
        }
      })
    },
    getVCode() {
      var myreg = /^((13[0-9])|(14[5-7,9])|(15[0-3,5-9])|(17[0-3,5-8])|(18[0-9])|(19[0-9])|166)\d{8}$/;
      if (!myreg.test(this.form.phone)) {
        this.$message({
          message: '请正确填写手机号码',
          customClass: 'myMessage',
          type: 'warning'
        });

        return false;
      }
      // vcodeBtnName 可以阻止按钮被多次点击 多次发送 return 会终止函数继续运行
      if (this.vcodeBtnName != '发送验证码' && this.vcodeBtnName != '重新发送') {

        return;
      }
      this.$post('index/CodeNumber/phoneNumber', {
        phone: this.form.phone + ''
      }
      ).then((res) => {
        if (res.code == 1) {
          this.vcodeBtnName = "发送中...";
          // 假设发送成功，给用户提示

          this.$message({
            message: '短信已发送，请注意查收',
            customClass: 'myMessage',
            type: 'success'
          });
          // 倒计时
          this.countNum = 60;
          this.countDownTimer = setInterval(() => {
            this.countDown();
          }, 1000);
        } else {
          this.$message({
            message: res.info,
            type: 'warning'
          });
        }
      })
    },
    countDown() {
      if (this.countNum < 1) {
        clearInterval(this.countDownTimer);
        this.vcodeBtnName = "重新发送";
        return;
      }
      this.countNum--;
      this.vcodeBtnName = this.countNum + '秒重发';
    },
    showImg() {
      this.spread = !this.spread
      // var that = this
      // setTimeout(() => {
      //   that.$refs.carousel.setActiveItem(i)
      // }, 100)
      this.left = 0

      this.getWidth()


    },
    changeSwiper(i) {
      if (i == 1) {
        this.$refs.carousel2.next()
      } else {
        this.$refs.carousel2.prev()
      }
    },
    switchShow(i, name) {
      this.swiperItems = []
      this.$post('index/user/childWithFiles', {
        id: i
      }
      ).then((res) => {
        if (res.code == 1) {
          this.handleWork(res.data, i)
          this.title = name

          var that = this
          setTimeout(() => {
            that.slideBanner()
          }, 500)
        }
      })
    },
    handleWork(data, i) {
      this.charge = []
      this.free = []
      let current = []
      data.forEach((item, index) => {

        item['pid'] = i
        if (item.free == 0) {

          if (item.files.length || item.pid == 1) {
            current.push(item)
          }

          if (current.length == 5) {
            this.charge.push(current)
            current = []
          }
          // console.log(index, data.length, current.length)


        } else {
          this.free.push(item)
        }
        if (index == data.length - 1 && current.length) {
          this.charge.push(current)
          current = []
        }
      })
      var that = this
      setTimeout(() => {
        that.$refs.carousel2.setActiveItem(0)
        that.playback()
      }, 500);
    },
    tianqi(city) {
      this.$get('https://yiketianqi.com/api?version=v5&appid=98218551&appsecret=kYg56EiH&city=' + city).then((res) => {
        if (res.errcode) {
          this.$message({
            message: res.errmsg,
            type: 'warning'
          });
          return
        }
        this.allWeather = res.data
        console.log(this.allWeather)
      })
    },
    worldTime(city, name) {
      this.$get('https://sapi.k780.com?app=time.world&appkey=66440&sign=0b6af1cb6d1684a532668dccffc46335&format=json&city_en=' + city).then((res) => {
        if (res.success == '1') {
          let data = res.result

          let days = data.datetime_1.split(' ')[0].split('-')
          let lunar = sloarToLunar(days[0], days[1], days[2])
          this.wordDate = {
            city: data.city_cn,
            day: data.datetime_1.split(' ')[0],
            week: data.week_2,
            lunar: lunar.lunarYear + '-' + lunar.lunarMonth + '-' + lunar.lunarDay,
            time: data.datetime_1.split(' ')[1].substring(0, 5)
          }
          console.log(this.wordDate, 'ressss')
          this.tianqi(name)
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          });
          var that = this
          setTimeout(() => {
            that.allWeather = false
          }, 100)
        }
      })
    },
    handle(res) {
      if (res.pid == 1 && !res.free) {
        this.worldTime(res.name_en, res.name)
        this.clickId = res.id
        return
      }
      // 判断是否是会员视频
      if (!res.free && (!this.usr || !this.usr.phone)) {
        this.dialogVisible = true
        return;
      }

      if (!res.free && (!this.usr || !this.usr.vip_code)) {
        this.$message({
          message: '请在手机微信小程序中购买会员！',
          type: 'warning'
        });
        return;
      }
      this.allWeather = false
      this.videoItem = ''
      if (res.files[0].marks == '视频') {
        var that = this
        setTimeout(() => {
          that.videoItem = 'https:' + res.files[0].cover
        }, 200)
        setTimeout(() => {
          document.getElementById('myVideo').play();
        }, 500)
        this.swiperItems = ''
        this.clickId = res.id
      } else {
        let imgArr = res.files[0].slider.split("|")
        this.videoItem = ''
        this.swiperItems = []
        imgArr.forEach((img) => {
          this.swiperItems.push({
            img: 'https:' + img.split("?")[0] + this.suffix
          })
        })
        this.clickId = res.id
      }
      console.log(this.videoItem, 'videoItem', this.swiperItems)
    },
    playback() {
      this.videoItem = ''
      this.swiperItems = []
      // 没有免费图片  是会员
      if (this.free.length == 0 && this.charge.length != 0 && this.usr && this.usr.vip_code) {
        this.handle(this.charge[0])
        this.clickId = this.charge[0].id
        // 免费图片 
      } else if (this.free.length != 0) {
        this.handle(this.free[0])
        this.clickId = this.free[0].id
      } else if (this.free.length == 0 && this.charge.length != 0) {
        this.videoItem = ''
        this.swiperItems = ''
      } else {
        // 展示默认图片
        this.title = '今天'
        this.videoItem = ''
        this.swiperItems = this.default
      }
    },
    healdWeather(i, res) {
      if (i == 0) {
        return "今天"
      } else if (i == 1) {
        return "明天"
      } else if (res.week) {
        return res.week
      } else if (i == 3) {
        let w = res.date_true.slice(-3, -1)
        return w.replace("周", "星期")
      } else {
        let week = ["星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"]
        let a = 0
        week.forEach((item, i) => {
          if (item == this.wordDate.week) {
            a = i
          }
        })
        a += 2
        if (a > 6) {
          a -= 7
        }
        return week[a]
      }
    },
    getSky(res) {
      let day = res.wea
      if (day.indexOf("雪") != -1) {
        return require('../assets/snow.png')
      } else if (day.indexOf("雨") != -1) {
        return require('../assets/rain.png')
      } else if (day.indexOf("晴") != -1) {
        return require('../assets/Sunny.png')
      } else {
        // 多云
        return require('../assets/yin.png')
      }
    },
  }
}
</script>
<style>
@import url("../assets/index.css");
/* video::-internal-media-controls-download-button {
  display: none;
} */
.roll-div {
  position: absolute;
  text-align: start !important;
  transition-delay: 2s !important;
}
.dialog-fade-enter-active {
  animation: text 0 !important;
  translate: all 0 !important;
}
.dialog-fade-leave-active {
  animation: text 0 !important;
  translate: all 0 !important;
}
.homr-banner {
  width: 100%;
  height: 100%;
}
.input-code {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.home-dialog input {
  text-align: inherit !important;
}
video {
  object-fit: fill;
}

@media screen and (orientation: portrait) {
  /*竖屏样式*/
  .myMessage {
    overflow: hidden !important;
    width: 30vmin !important;
    position: fixed !important;
    top: 44vmin !important;
    left: -15vmax !important;
    transform: rotateZ(-90deg) !important;
  }
}
.dialog-mobile {
  width: 100vmax;
  height: 100vmin;
  /* transform-origin: 0 0; */
  transform: rotateZ(-90deg) !important;
}
.full-screen {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vmax;
  height: 100vmin;
}
/* video {
  cursor: pointer;
}

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
} */
</style>